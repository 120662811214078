var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-nav-item-dropdown',{ref:"dropdown",staticClass:"nav-item",class:{
    'dropdown-menu-fixed-size': _vm.isFixedSize,
  },attrs:{"right":_vm.direction === 'right',"menu-class":"overflow-auto"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._t("badge",function(){return [(_vm.badge)?_c('span',{staticClass:"badge badge-danger",class:{
          'onlyNine': String(_vm.badge).length === 1,
          'overNinetyNine': String(_vm.badge).length > 2,
        },domProps:{"textContent":_vm._s(_vm.badge)}}):_vm._e()]}),_vm._v(" "),_vm._t("icon",function(){return [(_vm.icon)?_c('i',{staticClass:"icon-nav fas",class:_vm.icon}):_vm._e()]}),_vm._v(" "),_vm._t("text",function(){return [_c('span',{staticClass:"nav-text",domProps:{"textContent":_vm._s(_vm.title)}}),_vm._v(" "),_c('span',{staticClass:"sr-only",domProps:{"textContent":_vm._s(_vm.title)}})]})]},proxy:true}],null,true)},[_vm._v(" "),(_vm.viewIsMobile)?_c('b-dropdown-header',{staticClass:"for-mobile"},[_c('span',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('button',{staticClass:"btn btn-link",on:{"click":function($event){return _vm.hide()}}},[_c('i',{staticClass:"fas fa-times"})])]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"content",class:{
      'dropdown-menu-scrollable': _vm.isScrollable
    }},[_vm._t("content")],2),_vm._v(" "),(_vm.hasActionsSlot)?_c('b-dropdown-divider'):_vm._e(),_vm._v(" "),_vm._t("actions")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }